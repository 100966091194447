body {

}

.content-wrap,
#tinymce {
    h1,
    h2,
    h3,
    h4 {
        color: $color-primary;
    }

    h1{
        font-weight: 400;
        text-transform: uppercase;
        font-family: 'Droid Serif', serif;
        font-size: 25px;
    }

    h2 {

    }

    h3 {

    }

    h4 {

    }
}

.tagline{
    font-weight:300;
    font-size: $font-size-large;
}
