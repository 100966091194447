/****************************************************
*************** Scroll To-Top Button ****************
****************************************************/
.to-top {
    position: fixed;
    bottom: 1em;
    right: 1em;
    z-index: 100;
    border: 1px solid #fff;
    padding: .33em .75em;
    background: $color-primary;
    color: #fff;
    opacity: 1;
    mix-blend-mode: luminosity;
    transition: opacity $offcanvas-duration ease-in-out;

    .move-left &, .move-right & {
        opacity: 0;
    }
    &:hover{
        background: lighten($color-primary,10%);
        color:#FFF;
    }
}




/****************************************************
********************* CTA Buttons *******************
****************************************************/
%btn {
    display: inline-block;
    padding: 8px 18px 8px 18px;
    border-color: transparent;
    border-radius: 3px;
    font-size: 1.5rem;
    font-weight: 400;
    vertical-align: middle;
}

%cta-btn {
    background: $color-primary;
    color: #FFF;
}

%cta-btn-hover {
    &:hover,
    &:active,
    &:focus {
        background: lighten($color-primary,10%);
        color: #FFF;
    }
}


// Theme buttons, cf-form buttons, slideshow buttons
a.btn,
a.cta-btn,
a.caldera-forms-modal,
input[type="submit"].btn, {
    @extend %btn;
    @extend %cta-btn;
    @extend %cta-btn-hover;
}

// bb-plugin buttons
.fl-module-content .fl-button-wrap a.fl-button {
    @extend %btn;

    * {
        color: inherit;
    }

    .cta-btn & {
        @extend %cta-btn;
        @extend %cta-btn-hover;
    }
}

// uabb-addon buttons
.fl-module-content .uabb-button-wrap a.uabb-button {
    @extend %btn;

    .cta-btn &{
        @extend %cta-btn;
        @extend %cta-btn-hover;
    }
}

.btn-orange {
    background: $color-secondary url(../images/btn-orange.gif) !important;
    background-position: center right !important;
    background-repeat: no-repeat !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    text-align: left;
    padding: 4px 15px !important;
    position: relative;
    color: #fff !important;
    &:hover, &:active, &:focus {
        background: darken($color-secondary, 5) url(../images/btn-orange-hover.gif) !important;
        background-position: center right !important;
        background-repeat: no-repeat !important;
    }
}

.d21-trigger-ibe, .fl-button {
    @extend .btn-orange;
    padding-right: 40px !important;
}

/****************************************************
******************* Floaty Buttons ******************
****************************************************/
.floaty-wrap {
    position: fixed;
    right: 0;
    top: 30%;
    z-index: 100;

    @media screen and (max-width: 1600px) {
        display: none;
    }

    .floaty_buttons {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            margin-bottom: 15px;
        }

        .menu-item > a {
            display: block;
            padding: 0.75em 1.2em;
            transition: 250ms all ease-in-out;
            transform: translateX(1.2em);
            border-left: 5px solid #ea7734;
            box-shadow: 0 0 6px rgba(0,0,0,.5);
            background: #fff;
            opacity: 0.85;

            &:hover, &:focus, &:active {
                transform: translateX(0);
                -ms-transform: translateX(0);
                text-decoration: none;
                opacity: 1;
            }
        }
    }
}
