#desktop-nav {
    @extend .hidden-xs;
    @extend .hidden-is;
    position: relative;
    z-index: 100;
    width: 100%;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAABiCAYAAACRdbl9AAAA1klEQVQYlW3ESXKCQAAF0H+FAPYAiKlSMARxuEFW2cYBV14hzu6yUDBeIBf+2TQFncpbPHxNU+JTPf2TQ2yVQ2z1n1xip11i51vtfY/YB1aHoEMcQqtjKIhj1+rUlcQpqjtHijj36i49RVyetamQDvEhrObCIeaybiFdYqGavHZL5RFLXbfSHrHyO3bCVPiCKIK6dSCIdSjtlGkTaeKa9YlrNjDdsgFxG8WmchQTZR4TZZ6YqjwhqnFCVOMhcW+aDIn75MX03TRN2z2aZinxmL22+3l/4y/x0KHTD84ZRQAAAABJRU5ErkJggg==);
    height: 98px;

    nav {
        @extend .container;
        padding-top: 38px;
        position: relative;
        @media screen and (max-width: $screen-md-max) {
            span {
                display: none;
            }
        }
    }

    &.affix {
        position: fixed;
        top: 0;
        -webkit-box-shadow: 0px 3px 5px 0px rgba(102, 102, 102, 0.5);
        -moz-box-shadow:    0px 3px 5px 0px rgba(102, 102, 102, 0.5);
        box-shadow:         0px 3px 5px 0px rgba(102, 102, 102, 0.5);
        &.small_nav nav {
            &::before {
                content: '';
                position: absolute;
                background-image: url(../images/logo_small.png);
                width: 36px;
                height: 45px;
                left: -23px;
            }
        }
    }

    // Generic Menu item Styles
    li {
        a {
            padding: $nav-link-padding;
            color: $desktop-nav-link-color;
            text-transform: uppercase;
            font-family: 'Droid Serif', serif;
            font-size: 14px !important;

            &:hover {
                color: $color-secondary;
                text-decoration: none;
            }
        }
        &:hover a {
            color: $color-secondary;
            text-decoration: none;
        }

        // Second Nav Level
        .sub-menu {
            display: none;
            background: $color-secondary;

            background: #fff;
            border-bottom: 5px solid #EA7734;

            text-align: left;
            z-index: 101;
            box-shadow: 0 3px 5px 0 hsla(0,0%,40%,.5);
            opacity: 0.925;
            li {
                a {
                    text-transform: none;
                    font-family: inherit;
                    padding: 5px 10px;
                    color: $color-primary;
                    &:hover, &:active, &:focus {
                        background: lighten($color-secondary, 5);
                        text-decoration: none;
                        color: #fff;
                    }
                }
            }

            @media screen and (min-width: $screen-sm-min) {
                display: block;
                visibility: hidden;
                position: absolute;
                top: 100%;
                left: 0;
                min-width: 100%;
                transition: 0.1s .15s; /* delay of 0.15 seconds on hover off */
            }
        }
    }

    // Current Menu Item Styles
    .current-menu-item,
    .current-menu-ancestor {
        > a {
            color: $desktop-nav-link-active-bg;

            &:hover,
            &:active,
            &:focus {
                color: $desktop-nav-link-active-bg;
            }
        }
    }

    // Menu item description
    .has-description {
        .menu-item-description {
            display: inline-block;
            margin-right: .25em;
            width: 1em;
            vertical-align: middle;
            line-height: 1;

            img {
                display: inline-block;
                width: auto;
                height: auto;
                vertical-align: initial;
            }

            i {
                margin-top: -.265em;
            }
        }
    }
}




// Open First Level Sub-Menus of the last item to the left
#desktop-nav {
    nav {
        @media screen and (min-width: $screen-sm-min) {
            > ul {
                > li:last-child {
                    .sub-menu {
                        left: auto;
                        right: 0;

                        // Open Second Level Sub-Menus of the last item to the left
                        .sub-menu {
                            right: 100%;
                        }
                    }
                }
            }

            li:hover {
                > .sub-menu {
                    visibility: visible;
                    transition-delay: 0s;
                }
            }
        }
    }
}


// Third Nav Level
#desktop-nav {
    .sub-menu {
        .sub-menu {
            @media screen and (min-width: $screen-sm-min) {
                top: 0;
                left: 100%;
            }
        }
    }
}
