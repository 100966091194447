.nav,
.nav ul {
    @include list-unstyled();

    li {
        position: relative;
        font-size: $font-size-base;

        a {
            display: block;
        }
    }

    &.nav-center {
        display: block;
        margin: 0 auto;
        text-align: center;

        li {
            display: inline-block;
            margin: 0 1em;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &.nav-left {
        float: left;

        li {
            float: left;
            margin-right: 1em;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &.nav-right {
        float: right;

        li {
            float: left;
            margin-left: 1em;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}

.nav-justified {
    @extend %justified-list;
}
