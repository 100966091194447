#hero {
    position: relative;
    margin-top: 10px;
    width: 100%;

    .header-custom-image,
    .header-post-thumbnail,
    .header-default-image,
    .header-no-image {
        @extend .bg-img-cover;
        @extend .hero-area-content; // @ common/_helper-classes.scss
    }

    .header-no-image {
        background: $color-primary;
        > div {
            @extend .container;
        }
        p {
            width: 100%;
            padding: 1em;
            color: #fff;
            text-align: center;

            @media screen and (min-width: $screen-sm-min) {
                font-size: 1.5em;
            }
        }
    }
}
