/****************************************************
*************** General Footer Styles ***************
****************************************************/
#footer {
    color: #fff;
    border-top: 10px solid $color-tertiary;
    padding: 10px 0 30px;
    font-size: 15px;
    background: $color-primary;
    background: -ms-linear-gradient(top left, #6D1718 0%, #863031 100%);
    background: -moz-linear-gradient(top left, #6D1718 0%, #863031 100%);
    background: -o-linear-gradient(top left, #6D1718 0%, #863031 100%);
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0, #6D1718), color-stop(1, #863031));
    background: -webkit-linear-gradient(top left, #6D1718 0%, #863031 100%);
    background: linear-gradient(to bottom right, #6D1718 0%, #863031 100%);

    .fl-separator {
        border: 0;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA9QAAAACCAYAAAC5W5QrAAAALklEQVRYhe3DQREAIAgAMJJgB21DDWuSwxxm4L/dLSpXq6qqqrPx7j6qqqrq7AeEofr6fJpJdwAAAABJRU5ErkJggg==);
        height: 2px;
    }

    .fl-row div {
        padding: 0;
    }
    a {
        color: #fff;
    }
    h3 {
        font-size: 27.5px;
        text-transform: uppercase;
        line-height: 30px;
        font-weight: 400;
        float: left;
        margin-right: 25px;
        &:first-line {
            font-size: 24px;
        }
    }
    .btn {
        margin-bottom: 3px;
        width: 200px;
    }
    .footerbox {
        .uabb-infobox-cta-link {
            color: $color-secondary;
            &:after {
                content: "\e81c";
                font: normal normal normal 14px/1 custom-fontawesome;
                margin-left: 5px;
            }
        }
    }
    .footer_end {
        margin: 15px 0 0;
        iframe {
            height: 20px;
            width: 130px;
        }
    }
    @media screen and (max-width: $screen-is-max) {
        padding: 0 20px;
    }
}




/****************************************************
******************** Footer Menu ********************
****************************************************/
.footer-menu {
    @extend .hidden-xs;
    @extend .hidden-is;

    nav {
        @extend .container;

        #footer-nav {
            align-items: flex-start;

            // Generic #footer-nav list-item styles
            li {
                flex: 0 1 auto;
                text-align: left;
                vertical-align: top;

                &.current-menu-item > a {
                    font-weight: bold;
                }
            }

            > li {
                // First Level Sub-Menus
                > .sub-menu {
                    .menu-item a {
                        font-size: $font-size-small;
                    }
                }
            }
        }
    }
}




/****************************************************
************ Footer Widget Area Styles **************
****************************************************/
.footer-social-links {
    @extend .container;
    padding: $grid-gutter-width/2 0;
    text-align: center;

    h4 {
        margin: 0 0 $grid-gutter-width/2;
    }

    .social-links-list {
        @extend .nav;
        @extend .nav-center;
    }
}




/****************************************************
************ Footer Widget Area Styles **************
****************************************************/
.footer-widgets {
    @extend .container;
}




/****************************************************
******** Copyright Line (Last Line of site) *********
****************************************************/
.copyright {
    background: $color-tertiary;
    padding: 15px 0;
    margin-top: 20px;
    a {
        color: #fff;
    }

    .cookies-not-accepted & {
        padding-bottom: 5em;

        @media screen and (min-width: $screen-sm-min){
            padding-bottom: 3em
        }
    }
}

.copyright-row {
    @extend .row;
    font-size: $font-size-small;
    padding-top: 0;

    ul li {
        @extend .pipe-before;
        float: left;

        @media screen and (max-width: $screen-is-max){
            float: none;

            &::after,
            &::before {
                display: none;
            }
        }
    }

    // Unternehmensname, Copyright, Adresse etc.
    .copyright-info {
        @extend .nav;
        @include make-sm-column(7);
        text-align: center;

        @media screen and (min-width: $screen-sm-min){
            text-align: left;
        }

        li {
            margin: 0;
            font-size: $font-size-small;
        }
    }

    // Copyright Navigation (Kontakt, Impressum, Datenschutz etc.)
    .copyright-navigation {
        @include make-sm-column(5);

        .menu {
            @include list-unstyled;
            float: right;

            @media screen and (max-width: $screen-is-max){
                float: none;

                li {
                    float: none;
                    display: block;
                    text-align: center;
                }
            }
        }
    }
}
