// Error 404 Page Styles
.error404 {
    main {
        text-align: center;
    }
    .error404-content {
        padding-top: 1em;

        .search-form {
            padding: 1em;
        }
    }
}


.home {
    .listing {
        ul {
            list-style-type: none;
            li {
                margin-bottom: 20px;
                background: url(../images/listing.png);
                background-repeat: no-repeat;
                background-position: 0 1px;
                padding-left: 30px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .main {
        padding-top: 75px;

        @media screen and (max-width: $screen-is-max) {
            padding-top: 0;
        }
    }
}

.arrangement_box {
    .uabb-infobox-content {
        padding: 10px;
        border-bottom: 5px solid #ea7734;
        height: 450px;
        position: relative;
        overflow: hidden;
        -webkit-box-shadow: 0px 1px 10px 0px rgba(204, 204, 204, 1);
        -moz-box-shadow:    0px 1px 10px 0px rgba(204, 204, 204, 1);
        box-shadow:         0px 1px 10px 0px rgba(204, 204, 204, 1);
        .uabb-infobox-button {
            position: absolute;
            bottom: 20px;
            width: 100%;
            padding-right: 20px;
        }
    }
}

.arrangement_box {
    .uabb-infobox-content {
        height: auto;
    }
}

.white_with_shadow {
    background: #fff;
    box-shadow: 0 1px 10px #ccc;
    padding: 20px;
    @extend .col-xs-12;
}
