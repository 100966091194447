/****************************************************
********** General Sidebar & Widget Styles **********
****************************************************/
.sidebar {
    margin-top: 85px;

    .widget {
        margin-bottom: 1em;
    }

    ul {
        @extend .nav;
        margin-right: 20px;

        .sub-menu {
            width: 100%;
            a {
                font-size: 13px;
                white-space: nowrap;
                overflow: hidden;
                width: 225px;
                text-overflow: ellipsis;
                &:before {
                    content: "- ";
                }
                &:after {
                    content: "";
                }
            }
        }

        li {
            &.menu-item-has-children>a {
                padding: .5em !important;
            }
            &:last-child {
                border-bottom: none;
            }

            a {
                display: block;
                padding: .5em;
                padding-right: 25px;
                font-size: 15px;
                line-height: 16px;
                &:after {
                    content: "\e81c";
                    font-family: custom-fontawesome;
                    position: absolute;
                    right: 0;
                    top: 9px;
                }
            }

            // Shrink padding for parents, for better relation to children
            &.menu-item-has-children {
                > a {
                    padding-bottom: 0;
                }
            }

            // Styles for active menu items
            &.current-menu-item,
            &.current-post-parent,
            &.current_page_item,
            &.current_page_parent {

                > a {
                    color: $color-secondary;
                }
            }

            // Shrink padding of submenu items
            ul {
                li {
                    > a {
                        padding: .5em .75em .5em 1.5em;
                    }
                }
            }
        }
    }
}
