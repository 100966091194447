#header {
    @extend .hidden-xs;
    @extend .hidden-is;
    color: $color-primary;

    // First line of the website. Often contains contact information
    .header-contact {
        font-size: $font-size-small;
        margin-top: 10px;

        .email {
            margin-right: 10px;
        }

        .opening-times {
            @include make-sm-column(5);
        }

        .contact-infos {
            @include make-sm-column(7);

            ul {
                @extend .nav;
                @extend .nav-right;
                line-height: 1;

                li {
                    font-size: $font-size-small;

                    a,
                    i {
                        display: inline-block;
                        font-size: $font-size-small;
                        vertical-align: middle;

                        &::before {
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }

    // Wrapper for site logo and header widgets
    .header-content {
        .site-logo {
            @include make-sm-column(4);

            .logo-link {
                display: block;
                height: 100px;
                background-color: $brand-primary;
            }
        }

        .header-widgets {
            @include make-sm-column(2);
        }

        .header-navigation {
            @include make-sm-column(6);

            ul {
                &.stacked-icons {
                    i {
                        display: block;
                        margin: 0;
                        text-align: center;
                    }
                }
            }
        }
    }
    .custom-logo-link {
        display: inline-block;
        position: absolute;
        z-index: 99;
        top: -10px;
    }
    #booking_top_link {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALgAAAAiCAYAAADszhPiAAABbElEQVR4nO3XL0gEQRTH8U0m2zG7763VZBIuWU1Wg5isBkFE5LjZYrKaTILJarKaTKYDq8lqMlkFTcKxM+XC3Sw/vw8+ff98eTtbdd7+AKqq0hcALBOBQxqBQxqBQxqBQxqBQxqBQxqBQxqBQxqBQxqBQxqBQxqBQxqBQxqBQxqBQxqBQxqBQxqBQxqBQxqBQxqBQxqBQxqBQxqBQxqBQxqBo6ho/hHdZ/M68+fofptxnGia3eg+nnc+Gm1Uf1P6BjFM0du3fnjR2sd+dFPz62x4veii+/gkhPVq1VP6QWKB6My/k+jcZ9H8Pg3PLjPb7rAf3SSE7ZVHt8op/dJEfGa23UvuMztt/Cyz7faSjRfCZuk2JGYAcSyP+XtyvnN/SsIzu8l9Zru63umHd2oWSr8zZoEpFN9r5sfiIQmv8askOvej3PnuoKrWSj9LZoAzt+2+cue7zv0us/Emycarfb8f3YXZVun7Y/73/AJAJcZda/XKBwAAAABJRU5ErkJggg==);
        width: 184px;
        height: 34px;
        display: block;
        color: #fff;
        text-align: center;
        margin-top: -10px;
        float: right;
        &:hover, &:active, &:focus {
            text-decoration: none;
        }
        i {
            margin-right: 5px;
        }
    }
    #booking_div {
        background: $color-primary;
        position: absolute;
        top: 15px;
        right: 0;
        width: 100%;
        z-index: 101;
        margin-right: 15px;
        color: #fff;
        padding: 20px;
        display: none;
        form {
            text-align: right;
        }
        .btn {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 180px;
        }
        input {
            margin-right: 5px;
        }
        input, select {
            background: #fff;
            color: #444;
        }
        select:first-child {
            margin-right: 4px;
        }
        select:last-child {
            margin-right: 22px;
        }
        label {
            font-style: normal;
            margin-right: 5px;
        }
        #d21-headline-container {
            display: none;
        }
        #d21-book-button {
            @extend .btn-orange;
            padding: 0px 15px 4px !important;
        }
        #d21-quickbook {
            min-height: 0;
        }
    }
    .wpml-ls-legacy-list-horizontal {
        padding: 0;
        a {
            padding: 0;
            margin: 0 3px;
        }
    }
}

.booking_container {
    top: 100px;
    display: none;
    position: relative;
    .home & {
        display: block;
    }
    .fl-builder-edit & {
        display: none;
    }
    #booking_top {
        background: #fff;
        top: -18px;
        left: 0;
        z-index: 100;
        position: absolute;
        border-bottom: 5px solid #EA7734;
        padding: 7px 30px;
        -webkit-box-shadow: 0 0px 7px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0 0px 7px rgba(0, 0, 0, 0.5);
        box-shadow: 0 0px 7px rgba(0, 0, 0, 0.5);
        text-align: center;
        width: 100%;
        #d21-quickbook {
            max-width: 740px;
            width: 100%;
            &::before {
                content: 'Reservierung';
                color: $color-primary;
                font-weight: bold;
                text-transform: uppercase;
                float: left;
                margin: 3px 15px 0 0;
                font-size: 18px;
                @media screen and (max-width: $screen-sm-max) {
                    content: '';
                }
            }
            @media screen and (width: 768px) {
                div.halfWidth[id*="-container"] {
                    width: auto !important;
                }
            }
        }
        #d21-avail-result-container {
            display: none !important;
        }
        .d21-isNotGroup {
            min-height: 0;
        }
    }
}
